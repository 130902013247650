import styled from "styled-components"

export const TextContainer = styled.div`
  background-color: black;
  padding: 100px 5vw;
  p{
  margin-top: 0;
  }
  max-width: 100%;
  color: white;
  margin-bottom: 50px;
  @media only screen and (min-width: 600px){
    max-width: 50%;
    margin-bottom: 0;
  }
`;

export const SportsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 600px){
  flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  @media only screen and (max-width: 1200px){
      >div:last-of-type{
        position: absolute;
        top: 5%;
        left: 5% !important;
        max-width: 90%;
        font-size: 1rem;
      }
  }
  img{
  filter: grayscale(1);
  transform: scaleX(-1);
  }
  .gatsby-image-wrapper{
  height: 100% !important;
  }
 
  @media only screen and (min-width: 600px){
   width: 50%;
   >div:last-of-type{
    position: absolute;
    top: 5%;
    left: calc(50% - 300.367px);
  }
  height: auto;
  }
`;