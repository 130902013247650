import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeLanding from "../components/landing-home/landing.component"
import HomeAbout from "../components/home-about/home-about.component"
import Projects from "../components/projects/projects.component"
import HomeSports from "../components/home-sports/home-sports.component"
import HomeClients from "../components/home-clients/home-clients.component"
import HomeValue from "../components/values/values.component"
import {StyledDivider} from "../components/svg/svg.component";
import {Container} from "../components/home-about/home-about.style";


const IndexPage = () => {

    return (
        <Layout navScroll={true}>
            <SEO title="Home"/>
            <HomeLanding/>
            <HomeAbout/>
            <Projects/>
            <Container style={{width: '30%', margin: '50px auto'}}><StyledDivider/></Container>
            <HomeValue/>
            <HomeSports/>
            <HomeClients/>
        </Layout>
    )
}



export default IndexPage
