import React, {useEffect, useRef} from "react"
import { CompanyName, LandingText, LandingWrapper, Social } from "./landing.style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookSquare, faTwitterSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons'
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import OwlCarousel from 'react-owl-carousel';
import {gsap, Power3} from "gsap/all";



const HomeLanding = () => {
  let landingText = useRef(null);
  useEffect(() => {
    gsap.to(landingText.children, {duration: .8, y: 0, stagger: 1.2, opacity: 1, ease: Power3.easeIn})
  }, [])

  const data = useStaticQuery(graphql`
 query CarouselQuery {
  images: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
    nodes {
      id
      childImageSharp {
        fluid(maxWidth: 5000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}  
}
  `)

  const options = {
    loop: true,
    center: true,
    margin: 0,
    items: 1,
    nav: false,
    autoplay: true,
    dots: true,
  };

  return (
    <LandingWrapper>
        {
            typeof window !== `undefined`
                ? <OwlCarousel {...options} className="owl-theme" id="carousel-wrapper">
                    {data.images.nodes.map(image => (
                        <div className='item' key={image.id}>
                            <Img fluid={image.childImageSharp.fluid} style={{height: '100vh'}} alt="carousel"/>
                        </div>
                    ))
                    }
                </OwlCarousel>
                : null
        }
        <CompanyName>Qube Pcs</CompanyName>
        <LandingText ref={el => landingText = el}><span style={{color: 'rgba(255,255,255,0.70)', display: "block"}}>Managing risk whilst</span><span>Maximizing value</span></LandingText>
      <Social>
        <FontAwesomeIcon icon={faFacebookSquare}/>
        <FontAwesomeIcon icon={faTwitterSquare}/>
        <FontAwesomeIcon icon={faInstagramSquare}/>
      </Social>
    </LandingWrapper>
  )
}

export default HomeLanding;
