import React, {useEffect, useRef} from "react"
import { ProjectWrapper, Showcase, TextArea } from "./projects.style"
import { Paragraph, Title } from "../home-about/home-about.style"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import {paragraphAnim} from "../home-about/home-about.component";
import {gsap, Power3, ScrollTrigger} from "gsap/all";

const Projects = () => {
    let projectPara = useRef(null);
    let showcase = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(projectPara);
        gsap.from(showcase.children, {
            scrollTrigger: {
                trigger: showcase,
                start: 'top center',
            }, duration: .8, opacity: 0, x: -20, stagger: .8, ease: Power3.easeIn})
    })

  const data = useStaticQuery(graphql`
  query ProjectQuery {
  image: file(relativePath: {eq: "high-rise.jpg"}) {
    id
    childImageSharp {
      fixed(width: 300, height: 300) {
      ...GatsbyImageSharpFixed
      }
    }
  }
  image2: file(relativePath: {eq: "airplane.jpg"}) {
    id
    childImageSharp {
      fixed(width: 300, height: 300) {
      ...GatsbyImageSharpFixed
      }
    }
  }
  image3: file(relativePath: {eq: "sports1.jpg"}) {
    id
    childImageSharp {
      fixed(width: 300, height: 300) {
      ...GatsbyImageSharpFixed
      }
    }
  }
}
 `)


  return (
    <ProjectWrapper>
      <Showcase ref={el => showcase = el}>
        <Img style={{transform: 'translate(-100px, 200px) rotate(-10deg)'}} fixed={data.image.childImageSharp.fixed}/>
        <Img style={{transform: 'translate(100px, 0) rotate(2deg)', zIndex: 2}} fixed={data.image2.childImageSharp.fixed}/>
        <Img style={{transform: 'translate(-50px, -150px) rotate(8deg)'}} fixed={data.image3.childImageSharp.fixed}/>
      </Showcase>
      <TextArea>
        <Title>Our Projects</Title>
        <Paragraph ref={el => projectPara = el}>We have worked with Clients, Contractors and Designers both nationally and
            internationally on varying complex projects including:
            Aviation,
            Mixed-Use Commercial developments,
            Corporate developments,
            Utilities,
            Highways and Infrastructure,
            Universities and School complexes and
            Environmental projects.</Paragraph>
      </TextArea>

    </ProjectWrapper>
  )
}

export default Projects
