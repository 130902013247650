import React, {useEffect, useRef} from "react"
import {graphql, useStaticQuery} from "gatsby"
import {SecondParagraph, SectionWrapper, Title} from "../home-about/home-about.style"
import {ImageWrapper, SportsWrapper, TextContainer} from "./home-sports.style"
import Button from "../button/button.component"
import Img from "gatsby-image"
import {paragraphAnim} from "../home-about/home-about.component";
import {gsap, ScrollTrigger} from "gsap/all";

const HomeSports = () => {
    let sportsPara = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(sportsPara);
    })

    const data = useStaticQuery(graphql`
  query SportsQuery {
  image: file(relativePath: {eq: "sports2.jpg"}) {
    id
    childImageSharp {
      fluid(maxWidth: 5000) {
      ...GatsbyImageSharpFluid
      }
    }
  }
}
 `)

    return (
        <SectionWrapper>
            <SportsWrapper>
                <ImageWrapper>
                    <Img fluid={data.image.childImageSharp.fluid}/>
                    <Title>Sports Management</Title>
                </ImageWrapper>
                <TextContainer>
                    <SecondParagraph ref={el => sportsPara = el}>
                        We have a team of GFA accredited Intermediaries and Scouts; some with professional footballing
                        backgrounds as
                        ex-players
                        that can fully understand our clientele and provide clear and effective advice and support
                        through their
                        wealth
                        of knowledge and experience.
                    </SecondParagraph>
                    <Button type='secondary' link='/sports'>Read more</Button>
                </TextContainer>
            </SportsWrapper>
        </SectionWrapper>
    )
}

export default HomeSports
