import styled from "styled-components"
import {palette} from "../../utils/elements";


export const ClientsWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5vw;
   p{
  max-width: 700px;
  }
  a{
  margin-top: 50px;
  }
  @media only screen and (min-width: 600px){
  padding: 0;
  }
  .owl-nav{
    display: flex;
    justify-content: space-around;
    button{
    width: 50px;
    &:hover{
    background-color: transparent !important;
    }
    &:active, &:focus{
    outline: none;
    }
    &:hover span{
    color: ${palette.byzantium};
    }
    span{
    font-size: 6rem;
    position: relative;
    }
    }
    .owl-prev span::after{
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    content: '';
    width: 50px;
    height: 7px;
    position: absolute;
    top: calc(50% + 5px);
    pointer-events: none;
    left: 60px;
    opacity: 0;
    background-color: ${palette.byzantium};
    display: inline-block;
    }
    .owl-next span::before{
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    content: '';
    width: 50px;
    height: 7px;
    position: absolute;
    top: calc(50% + 5px);
    pointer-events: none;
    opacity: 0;
    right: 60px;
    background-color: ${palette.byzantium};
    display: inline-block;
    }
    .owl-prev:hover{
    & span:after{
    left: 20px;
    opacity: 1;
    }
    } 
    .owl-next:hover{
    & span:before{
    right: 20px;
    opacity: 1;
    }
    }
  }
`;

export const ClientImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin-top: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .owl-carousel{
  margin-bottom: 50px !important;
  }
  *{
  object-fit: contain;
  margin: 0;
  }
 
`;