import styled from "styled-components"
import { palette, typeScale } from "../../utils/elements"



export const LandingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  text-transform: uppercase;
  .owl-item::before {
      content: '';
      display: inline-block;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,0.70);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
  }
  .owl-dots{
        position: absolute;
        bottom: 30px;
        width: 500px;
        display: inline-flex;
        justify-content: center;
        transform: rotate(90deg);
        align-items: flex-start;
        right: -210px;
        z-index: 2;
        
        button:active, button:focus{
        outline: none;
        }
        
        span{
        background-color: #bfbfbf !important;
        border-radius: 0 !important;
        width: 2px !important;
        height: 20px !important;
        }
        @media only screen and (min-width: 900px){
        bottom: 50%;
        right: -150px;
        left: auto;
        }
  }
  .owl-carousel .active span{
        background: ${palette.byzantium} !important;
         height: 40px !important;

    }
`;

export const CompanyName = styled.h4`
  display: none;
  font-size: ${typeScale.paragraph};
  color: white;
  transform: rotate(-90deg);
  margin-left: -20px;
  position: absolute;
  top: calc(50% - 20px);
  left: 5%;
  z-index: 2;
  @media only screen and (min-width: 900px){
    display: inline-block;
  }
`

export const LandingText = styled.h1`;
  font-size: ${typeScale.header3};
  *{
  transform: translateY(50px);
  opacity: 0;
  }
  >span:first-of-type{
  font-size: ${typeScale.paragraph};
  }
  color: white;
  text-align: center;
  position: absolute;
  top: calc(50% - 53px);
  left: calc(50% - 185px);
  z-index: 2;
  @media only screen and (min-width: 900px){
  font-size: ${typeScale.header1};
  >span:first-of-type{
  font-size: ${typeScale.header5};
  }
  top: calc(50% - 50px);
  left: calc(50% - 330px);
`;

export const Social = styled.div`
  position: absolute;
  bottom: 40px;
  left: 5%;
  z-index: 2;
  color: white;
  font-size: 1.4rem;
  transition: 500ms ease-in-out;
  *{
  margin-right: 20px;
  cursor: pointer;
  &:hover{
  color: ${palette.byzantium};
  }
  }
`;