import React, {useEffect, useRef} from "react"
import {Container, Paragraph, SectionWrapper, Title} from "../home-about/home-about.style"
import {ClientImageWrapper, ClientsWrapper} from "./home-clients.style"
import OwlCarousel from 'react-owl-carousel';
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import {StyledDivider} from "../svg/svg.component";
import {paragraphAnim} from "../home-about/home-about.component";


const HomeClients = () => {
    let clientsPara = useRef(null);

    useEffect(() => {
        paragraphAnim(clientsPara);
    })

    const data = useStaticQuery(graphql`
  query ClientQuery {
  images: allFile(filter: {relativeDirectory: {eq: "clients"}}) {
    nodes {
      id
      childImageSharp {
        fluid(maxWidth: 5000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}  
}
  `)


    const options = {
        loop: true,
        margin: 100,
        items: 4,
        autoplay: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1.4,
                center: true,
                margin: 10
            },
            768: {
                items: 2.5,
            },
            1000: {
                items: 3.8,
            },
        },
    };


    return (
        <SectionWrapper id='clients'>
            <ClientsWrapper>
                <Title>Clients</Title>
                <Paragraph ref={el => clientsPara = el}>Our team members have worked for and represented clients from
                    the public and private sectors on major flagship projects.</Paragraph>
                <Container style={{width: '20%', margin: '50px auto'}}><StyledDivider/></Container>
                {
                    typeof window !== `undefined`
                        ? <OwlCarousel {...options} className="owl-theme" id="carousel-wrapper">
                            {data.images.nodes.map(image => (
                                <ClientImageWrapper className='item' key={image.id}>
                                    <Img fluid={image.childImageSharp.fluid} alt="client's logos"/>
                                </ClientImageWrapper>
                            ))
                            }
                        </OwlCarousel>
                        : null
                }
            </ClientsWrapper>
        </SectionWrapper>
    )
}

export default HomeClients
