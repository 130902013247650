import styled from "styled-components"

export const ProjectWrapper = styled.section`
  display: flex;
  padding: 0 5%;
  flex-direction: column;
  >div{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  @media only screen and (min-width: 600px){
    flex-direction: row;
    >div{
      width: 50%;
    }
  }
`;

export const TextArea = styled.div`
`;

export const Showcase = styled.div`
  position: relative;
  >*{
  border: 7px solid black;
  position: absolute;
  top: 0;
  left: 0;
  @media only screen and (max-width: 1200px){
  width: 150px !important;
  height: 150px !important;
 
  &:nth-of-type(1){
  transform: translate(-40px, 100px) rotate(-10deg) !important;
  }
  &:nth-of-type(2){
  transform: translate(40px, 0px) rotate(2deg) !important;
  }
  &:nth-of-type(3){
  transform: translate(-40px, -80px) rotate(8deg) !important;
  }
  }
  }
`;